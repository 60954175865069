import { MobilityProcessRespone } from "@/api/generated/ElectraInterfaces";
import { makeVar } from "@apollo/client";
import { FormState } from "./indes.modal";

type ProcessType = MobilityProcessRespone & {
  selectedPackageName: string | null;
};

const formVarInitialState: FormState = {
  firstName: "",
  lastName: "",
  id: "",
  email: "",
  phone: "",
  street: "",
  city: "",
  houseNumber: "",
  apartmentNumber: "",
};

const processVarInitialState: ProcessType = {
  accountNumber: "",
  billingPlan: "",
  canTransforme: false,
  meterNumber: "",
  phoneNumber: "",
  processId: "",
  processStatus: null,
  smartMeter: false,
  selectedPackageName: null,
};

export const formVar = makeVar<FormState>(formVarInitialState);

export const proccesVar = makeVar<ProcessType>(processVarInitialState);

export const paymentVar = makeVar({
  url: "",
});

export const endingVar = makeVar({
  title: "",
  detailes: "",
  accounts: "",
});

export const userVar = makeVar({
  checkedSocial: false,
  progress: 0,
  checkedBundle: false,
  packageChosen: "",
});

export const layoutVar = makeVar({
  openGeneralError: false,
  hideHeader: false,
});

export const clearFormVar = () => {
  formVar(formVarInitialState);
};

export const clearProcessVar = () => {
  proccesVar(processVarInitialState);
};
export const clearPaymentVar = () => {
  paymentVar({ url: "" });
};
export const clearEndingVar = () => {
  endingVar({ title: "", detailes: "", accounts: "" });
};

export const clearUserVar = () => {
  userVar({
    checkedSocial: false,
    progress: 0,
    checkedBundle: false,
    packageChosen: "",
  });
};

export const showGeneralError = () => {
  layoutVar({ ...layoutVar(), openGeneralError: true });
};
export const hideGeneralError = () => {
  layoutVar({ ...layoutVar(), openGeneralError: false });
};
